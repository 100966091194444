<template>
    <div class="text-center">
        <h3>Oops, page not found...</h3>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
};
</script>

<style scoped>
.text-center {
    margin: auto;
    width: 40%;
    padding-top: 15%;
    color: white;
    font-size: 200%;
}
</style>
